import React from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { navigate } from "gatsby"

import Layout from "../../components/admin/layout"
import Modal from "../../components/common/modal"
import FileWrapper from "../../components/common/fileWrapper"
import Dropdown from "../../components/common/dropdown"
import request, { getUserRole } from "../../request"

class NewsLink extends React.Component {
  state = {
    name: this.props.data.name || "",
    password: this.props.data.password || "",
    url: this.props.data.url || "",
    fileName: this.props.data.fileName || "",
  }

  emitChange = () => {
    const { onChange, data } = this.props
    onChange && onChange(data.id, this.state)
  }

  onNameChange = e => {
    this.setState({ name: e.target.value }, this.emitChange)
  }

  onPasswordChange = e => {
    this.setState({ password: e.target.value }, this.emitChange)
  }

  onUrlChange = e => {
    this.setState({ url: e.target.value }, this.emitChange)
  }

  onDropdownChange = item => {
    const { urlToName } = this.props
    this.setState(
      { url: item.url, fileName: urlToName[item.url] },
      this.emitChange
    )
  }

  onResetUrl = e => {
    e.preventDefault()
    this.setState({ url: "", fileName: "" }, this.emitChange)
  }

  render() {
    const { onRemove, data, media } = this.props
    const { name, password, url, fileName } = this.state
    const items = media || []

    return (
      <div className="ad-new__link-wrapper">
        <div className="ad-news__link">
          <div className="ad-news__link-item-wrapper">
            <div className="ad-news__link-item">
              <div className="ad-news__link-text">顯示名稱</div>
              <input
                className="ad-news__link-input"
                type="text"
                placeholder="例:瀝青公會首頁圖片"
                value={name}
                onChange={this.onNameChange}
              />
            </div>
            <div className="ad-news__link-item hidden">
              <div className="ad-news__link-text">驗證碼</div>
              <input
                className="ad-news__link-input"
                type="password"
                placeholder="(若不設定則表示不需驗證碼即可開啟連結或下載檔案)"
                autoComplete="no"
                value={password}
                onChange={this.onPasswordChange}
              />
            </div>
            <div className="ad-news__link-item">
              <div className="ad-news__link-text">連結位址</div>
              <input
                className={`ad-news__link-input ${fileName ? "hidden" : ""}`}
                type="text"
                placeholder="例:https://taia2-backup-files.s3-ap-northeast-1.amazonaws.com/picture/logo2.gif"
                value={url}
                onChange={this.onUrlChange}
              />
              <input
                className={`ad-news__link-input ${fileName ? "" : "hidden"}`}
                type="text"
                defaultValue={fileName}
                readOnly
              />
              <Dropdown
                className={fileName ? "hidden" : ""}
                label="從媒體庫"
                items={items}
                nameKey="name"
                onSelect={this.onDropdownChange}
              />
              <button
                className={fileName ? "" : "hidden"}
                onClick={this.onResetUrl}
              >
                重設
              </button>
            </div>
          </div>
          <div className="ad-news__link-remove">
            <button
              onClick={e => {
                e.preventDefault()
                onRemove && onRemove(data.id)
              }}
            >
              移除此連結
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const prefix = "__MEDIA__"

const addPrefix = file => {
  const blob = file.slice(0, file.size, file.type)
  const newFile = new File([blob], `${prefix}${file.name}`, {
    type: file.type,
  })
  return newFile
}

const removePrefix = file => ({
  ...file,
  name: file.name.replace(prefix, ""),
})

const blankNews = () => ({
  publishDate: new Date(),
  content: "",
  links: [],
  category: 'news',
})

const deepClone = data => JSON.parse(JSON.stringify(data))

const fmtDate = dateStr => {
  const date = new Date(dateStr)
  let mm = date.getMonth() + 1 // getMonth() is zero-based
  let dd = date.getDate()
  mm = (mm > 9 ? "" : "0") + mm
  dd = (dd > 9 ? "" : "0") + dd
  return `${date.getFullYear()}/${mm}/${dd}`
}

export default class News extends React.Component {
  state = {
    reveal: false,
    newsList: [],
    tempNews: blankNews(),
    media: [],
    urlToName: {},
    currentTab: "news",
    uploading: false,
    percentage: 0,
  }

  sortByDate = (a, b) => new Date(b.publishDate) - new Date(a.publishDate)

  getUrlToName = media =>
    media.reduce((accu, curr) => {
      accu[curr.url] = curr.name
      return accu
    }, {})

  onConfirm = () => {
    const { tempNews, newsList } = this.state
    let invalid = !tempNews.content
    for (let link of tempNews.links) {
      if (!link.name || !link.url) {
        invalid = true
        continue
      }
      if (!link.url.startsWith("http://") && !link.url.startsWith("https://")) {
        link.url = `https://${link.url}`
      }
    }
    if (invalid) {
      return alert("消息內容或任一連結顯示名稱或位址不得為空白")
    }
    const news = deepClone(tempNews)
    if (tempNews.id) {
      request.putNews(tempNews.id, news).then(res => {
        this.setState({
          tempNews: news,
          reveal: false,
          newsList: newsList
            .map(n => (n.id === tempNews.id ? res.data : n))
            .sort(this.sortByDate),
        })
      })
    } else {
      request.postNews(news).then(res => {
        this.setState({
          tempNews: news,
          reveal: false,
          newsList: [...newsList, res.data].sort(this.sortByDate),
        })
      })
    }
  }

  onCancel = () => {
    this.setState({ reveal: false })
  }

  onRemoveLink = id => {
    this.setState({
      tempNews: {
        ...this.state.tempNews,
        links: this.state.tempNews.links.filter(link => link.id !== id),
      },
    })
  }

  onUpdateLink = (id, state) => {
    this.setState({
      tempNews: {
        ...this.state.tempNews,
        links: this.state.tempNews.links.map(link => {
          if (link.id !== id) return link
          return { id, ...state }
        }),
      },
    })
  }

  onFileChange = (files, reset) => {
    const mb = files[0].size / 1024 / 1024
    if (mb > 100) {
      alert("檔案大小不得超過100MB")
      reset()
      return
    }
    try {
      files = files.map(addPrefix)

      const formData = new FormData()
      formData.append("files", files[0])
      this.setState({ uploading: true })
      request
        .postMedia(formData, progress => {
          this.setState({ percentage: progress.percentage })
        })
        .then(res => {
          const file = res.data[0]
          const media = [removePrefix(file), ...this.state.media]
          this.setState({
            uploading: false,
            media,
            urlToName: this.getUrlToName(media),
          })
          reset()
        })
        .catch(e => {
          reset()
          this.setState({ uploading: false })
        })
    } catch {
      alert("目前所使用的瀏覽器不支援此功能,請使用其他瀏覽器進行檔案上傳!!")
    }
  }

  removeMedia = id => {
    request.deleteMedia(id).then(() => {
      const media = this.state.media.filter(media => media.id !== id)
      this.setState({ media, urlToName: this.getUrlToName(media) })
    })
  }

  removeNews = id => {
    request.deleteNews(id).then(() => {
      this.setState({
        newsList: this.state.newsList.filter(news => news.id !== id),
      })
    })
  }

  editNews = news => {
    this.setState({ reveal: true, tempNews: deepClone(news) })
  }

  componentDidMount() {
    const role = getUserRole()
    if (role === 0) return navigate("/admin/login")
    if (role !== 1) return navigate("/admin/announcement")

    request.getMedia({ name_contains: prefix, _sort: "id:DESC" }).then(res => {
      const media = res.data.map(removePrefix)
      this.setState({ media, urlToName: this.getUrlToName(media) })
    })
    request.getNews({ category_eq: 'news' }).then(res => {
      this.setState({ newsList: res.data.sort(this.sortByDate) })
    })
  }

  render() {
    if (!getUserRole()) return null

    const {
      reveal,
      newsList,
      tempNews,
      currentTab,
      uploading,
      percentage,
      media,
      urlToName,
    } = this.state

    const isNew = !tempNews.id
    return (
      <Layout>
        <div className="ad-news__wrapper">
          <div className="ad-news__breadcrumbs">
            <span
              className={`ad-news__breadcrumb ${
                currentTab === "news" ? "ad-news__breadcrumb--active" : ""
              }`}
              onClick={() => this.setState({ currentTab: "news" })}
            >
              最新消息
            </span>
            &nbsp;/&nbsp;
            <span
              className={`ad-news__breadcrumb ${
                currentTab === "files" ? "ad-news__breadcrumb--active" : ""
              }`}
              onClick={() => this.setState({ currentTab: "files" })}
            >
              媒體庫
            </span>
          </div>
          <div className="ad-common-controls">
            <button
              className={currentTab === "news" ? "" : "hidden"}
              onClick={() =>
                this.setState({ reveal: true, tempNews: blankNews() })
              }
            >
              新增消息
            </button>
            <FileWrapper onFileChange={this.onFileChange}>
              <button
                className={currentTab === "files" ? "" : "hidden"}
                disabled={uploading}
              >
                {uploading ? `檔案上傳中... (${percentage}%)` : "新增媒體"}
              </button>
            </FileWrapper>
          </div>
          <hr />
          <br />
          <table
            className={`ad-news__table ad-news__table--hover ${
              currentTab === "news" ? "" : "hidden"
            }`}
          >
            <thead>
              <tr>
                <th>發布日期</th>
                <th>消息內容</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {newsList.map(news => (
                <tr key={news.id} onClick={() => this.editNews(news)}>
                  <td>{fmtDate(news.publishDate)}</td>
                  <td>{news.content}</td>
                  <td>
                    <button
                      onClick={e => {
                        e.stopPropagation() // don't trigger row click
                        // eslint-disable-next-line no-restricted-globals
                        if (confirm("確定刪除此消息?")) {
                          this.removeNews(news.id)
                        }
                      }}
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <table
            className={`ad-news__table ${
              currentTab === "files" ? "" : "hidden"
            }`}
          >
            <thead>
              <tr>
                <th>檔案名稱</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {media.map(file => (
                <tr key={file.id}>
                  <td>{file.name}</td>
                  <td>
                    <button
                      onClick={e => {
                        e.preventDefault()
                        // eslint-disable-next-line no-restricted-globals
                        if (confirm("確定刪除此檔案?")) {
                          this.removeMedia(file.id)
                        }
                      }}
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Modal
          reveal={reveal}
          size="lg"
          header={isNew ? "新增消息" : "編輯消息"}
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}
          textConfirm={isNew ? "新增" : "更新"}
        >
          <form className="ad-news__form">
            <div className="ad-news__publish">
              <div>發布日期:&nbsp;</div>
              <DatePicker
                dateFormat="yyyy/MM/dd"
                selected={new Date(tempNews.publishDate)}
                onChange={date => {
                  this.setState({
                    tempNews: {
                      ...tempNews,
                      publishDate: date,
                    },
                  })
                }}
              />
            </div>
            <textarea
              placeholder="請在此輸入要發布的消息"
              value={tempNews.content}
              onChange={e => {
                this.setState({
                  tempNews: {
                    ...tempNews,
                    content: e.target.value,
                  },
                })
              }}
            />
            <div className="ad-news__btn-add">
              <button
                onClick={e => {
                  e.preventDefault()
                  this.setState({
                    tempNews: {
                      ...tempNews,
                      links: [...tempNews.links, { id: Date.now() }],
                    },
                  })
                }}
              >
                添加連結
              </button>
            </div>
            <div className="ad-news__links">
              {tempNews.links.map(link => (
                <NewsLink
                  data={link}
                  key={link.id}
                  media={media}
                  urlToName={urlToName}
                  onChange={this.onUpdateLink}
                  onRemove={this.onRemoveLink}
                />
              ))}
            </div>
          </form>
        </Modal>
      </Layout>
    )
  }
}
